<template>
  <div class="z-10">
    <div
      v-if="!hideActivator"
      class="flex p-2 mx-0.5 cursor-pointer"
      @click="dialougeValuePlaces = true"
    >
      <v-icon icon="mdi mdi-map-marker-left-outline" :size="24" />
      <div v-if="selectedPlace?.text_address">
        {{ selectedPlace?.text_address }}
      </div>
    </div>
    <MevcutDialouge
      v-model="dialougeUpdateValuePlaces"
      width="auto"
      :with-vmodel="true"
    >
      <template #content>
        <div class="min-w-72 px-5 pt-4 pb-13 text-black relative">
          <div class="fixed bottom-4 rtl:left-4 ltr:right-4 z-10">
            <MevcutDialouge width="auto">
              <template #activator="{ props: propsTemp }">
                <div
                  class="rounded-full p-2 z-10 cursor-pointer"
                  v-bind="propsTemp"
                >
                  <v-icon icon="mdi mdi-plus" />
                </div>
              </template>
              <template #content="{ close }">
                <MyAccountPlacesCreateForm
                  class="p-2"
                  @created=";[close(), (dialougeUpdateValuePlaces = false)]"
                />
              </template>
            </MevcutDialouge>
          </div>

          <div class="flex justify-center text-text_color">
            <v-icon icon="mdi mdi-city" />
          </div>
          <div class="text-center text-xl font-semibold mb-4">
            {{ t('select_place') }}
          </div>

          <div>
            <div v-if="!places.length">
              <div class="flex justify-center items-center">
                <v-icon
                  icon="mdi mdi-information"
                  :size="15"
                  class="mx-1 flex-shrink-0"
                />
                <div class="break-all text-xs">
                  {{ t('no_addresses_currently_saved') }}
                </div>
              </div>
            </div>

            <div
              v-for="(place, index) in places"
              :key="`locale-item ${index}`"
              :class="{ 'text-text_color': place?.id == selectedPlace?.id }"
              class="my-1 text-center cursor-pointer px-2"
              @click="selectPlace(place)"
            >
              <div
                class="flex justify-between items-center py-2"
                :class="{
                  'border-b': places[places.length - 1]?.id != place.id
                }"
              >
                <div>
                  <div>
                    {{ place.text_address }}
                  </div>
                  <div>
                    {{ place.details ?? place.city?.name ?? '' }}
                  </div>
                </div>
                <div class="flex items-center">
                  <MevcutLoadingIndicator
                    v-if="loading[place.id] || false"
                    :size="4"
                    color="border-nav_color"
                    class="text-nav_color h-full"
                    :loading="loading[place.id]"
                  />
                  <v-icon
                    v-if="selectedPlace?.id == place.id"
                    icon="mdi mdi-check-circle"
                    size="16"
                  />
                  <v-icon icon="mdi mdi-chevron-left" class="ltr:rotate-180" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </MevcutDialouge>
    <MevcutDialouge
      v-model="dialougeValueWarining"
      :with-vmodel="true"
      width="auto"
    >
      <template #content>
        <div class="min-w-72 px-5 py-4">
          <div class="flex justify-center text-text_color">
            <v-icon icon="mdi mdi-alert" />
          </div>
          <div class="text-center mb-4 text-black">
            {{ t('warning_chage_place') }}
          </div>
          <div class="flex justify-center mt-10">
            <div class="w-20">
              <MevcutSubmitButton
                :title="t('apply')"
                text-color="text-white text-xs sm:text-base"
                type="button"
                @click="applyChange()"
              />
            </div>

            <div class="w-2 xs:w-5"></div>

            <div class="w-20">
              <MevcutSubmitButton
                color="bg-button_color"
                text-color="text-text_alt_color text-xs sm:text-base"
                type="button"
                :title="t('close')"
                @click="dialougeValueWarining = false"
              />
            </div>
          </div>
        </div>
      </template>
    </MevcutDialouge>
  </div>
</template>

<script setup lang="ts">
import { usePlacesStore } from '~/store/places'
const { t } = useI18n()
const { isHasItemsByPerviousCityId } = useCartStore()
const { updateAgentPlace } = usePlacesStore()
const props = defineProps<{
  forceSelector?: boolean
  hideActivator?: boolean
}>()
const selectedPlace = computed(() => {
  return usePlacesStore().selectedPlace
})

const places = computed(() => {
  return usePlacesStore().places
})

const newPlaceTempToChange = ref<AgentPlace | undefined>(undefined)
const dialougeValueWarining = ref<boolean>(false)
const dialougeValuePlaces = ref<boolean>(false)

onMounted(() => {
  if (props.forceSelector) {
    dialougeValuePlaces.value = true
  }
})
const dialougeUpdateValuePlaces = computed({
  get() {
    return dialougeValuePlaces.value
  },

  set(value) {
    if (selectedPlace.value?.id) {
      dialougeValuePlaces.value = value
    }
  }
})
const { serverLoad } = useCartStore()
const loading = ref<Record<string, boolean | undefined>>({})
async function updateAgentPlaceServer(newPlace: AgentPlace) {
  loading.value[newPlace.id] = true
  try {
    await updateAgentPlace(newPlace).then(() => {
      dialougeValuePlaces.value = false
      loading.value = {}
      serverLoad()
    })
  } catch (error) {
    loading.value = {}
  }
}
async function selectPlace(newPlace: AgentPlace) {
  if (Object.keys(loading.value).length) {
    return
  }
  dialougeValueWarining.value = false
  if (newPlace?.id === selectedPlace.value?.id) {
    dialougeValuePlaces.value = false
    return
  }
  if (props.forceSelector) {
    await updateAgentPlaceServer(newPlace)
  }
  if (
    newPlace?.city_id === selectedPlace.value?.city_id ||
    !isHasItemsByPerviousCityId(selectedPlace.value?.city_id)
  ) {
    updateAgentPlaceServer(newPlace)
    return
  }
  newPlaceTempToChange.value = newPlace
  dialougeValueWarining.value = true
}

async function applyChange() {
  dialougeValueWarining.value = false
  await updateAgentPlaceServer(newPlaceTempToChange.value!).then(() => {
    newPlaceTempToChange.value = undefined
  })
}
</script>
<style scoped>
.dropdown-menu {
  @apply overflow-y-auto bg-bg_alt_color mt-2 text-text_color  max-h-48;
}
</style>
