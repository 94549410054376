<template>
  <div>
    <ClientOnly>
      <div v-if="!hideActivator" class="flex p-2 item-center mx-0.5 cursor-pointer " @click="dialougeValueCities=true">
        <v-icon icon="mdi mdi-map-marker-left-outline" :size="24" />
        <div v-if="selectedCity?.name">
          {{ selectedCity?.name }}
        </div>
        <v-icon v-if="selectedCity?.name" icon="mdi-chevron-down" />
      </div>
    </ClientOnly>
    <MevcutDialouge
      v-model="dialougeUpdateValueCities"
      width="auto"
      :with-vmodel="true"
    >
      <template #content>
        <div class="min-w-72 px-5 py-4 text-black bg-white max-h-[85vh]">
          <div class="flex justify-center">
            <v-icon icon="mdi mdi-city" />
          </div>
          <div class="text-center text-xl font-semibold mb-4">
            {{ t('select_city') }}
          </div>

          <div
            v-for="(city, index) in cities"
            :key="`locale-item ${index}`"
            :class="{ 'text-text_color': selectedCity?.id == city.id }"
            class="my-1 text-center cursor-pointer px-2"
            @click="selectCity(city.id)"
          >
            <div
              class="flex justify-between py-2"
              :class="{ 'border-b': cities[cities.length - 1]?.id != city.id }"
            >
              <div>
                {{ city.name }}
              </div>
              <div class="flex items-center">
                <v-icon v-if="selectedCity?.id == city.id " icon="mdi mdi-check-circle" size="16" />
                <v-icon icon="mdi mdi-chevron-left" class="ltr:rotate-180" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </MevcutDialouge>
  </div>
</template>

<script setup lang="ts">
import { usePlacesStore } from '~/store/places'
const { clearCache } = useCacheSession()
const { t } = useI18n()
const { cities, setCityId } = usePlacesStore()
const selectedCity = computed(() => {
  return usePlacesStore().selectedCity
})
const props = defineProps<{
  forceSelector?: boolean,
  hideActivator?: boolean
}>()
const dialougeValueCities = ref<boolean>(false)

onMounted(() => {
  if (props.forceSelector) {
    dialougeValueCities.value = true
  }
})
const dialougeUpdateValueCities = computed({
  get() {
    return dialougeValueCities.value
  },

  set(value) {
    if (selectedCity.value?.id) {
      dialougeValueCities.value = value
    }
  }
})
function selectCity(cityId: number) {
  if (selectedCity.value?.id !== cityId) {
    setCityId(cityId.toString())
    clearCache()
    dialougeValueCities.value = false
  }
}
</script>
<style scoped>
.dropdown-menu {
  @apply overflow-y-auto bg-bg_alt_color mt-2 text-text_color  max-h-48;
}
</style>
